import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import useShow from "../../hooks/useShow";
import styles from "./swiper.module.scss";
import "swiper/css";
const SwiperComponent = ({
  items,
  primary,
  page,
  level,
  handlerFilters,
  checkIsActive,
  showLine,
  appliedFilters,
  allBrands,
  brands,
  myBrand,
  setAppliedFilters,
}) => {

  const show = useShow();
  const container = primary ? styles.container : styles.containerPromotions;
  const slide = primary ? styles.item : styles.promotions;
  const key = (i) => (primary ? `swiperCategory ${i}` : `swiperPromotion ${i}`);

    
  // console.log("items estos son", items);
  // console.log(brands)
  // console.log(allBrands)
  // console.log(myBrand)

  const brandsToShow = primary
    ? items.categories.reduce((acc, category) => {
        if (appliedFilters.includes(category))
          acc.push(...items.showBy[category]);
        return acc;
      }, [])
    : [];

  let itemsFilters = primary
    ? level !== "brands"
      ? items[level]
      : items[level].filter(
          (brand) => !brandsToShow.length || brandsToShow.includes(brand)
        )
    : [];

  const itemsToRender = primary
    ? itemsFilters.map((item) => {
        return <p key={`swiper ${item}`}>{item}</p>;
      })
    : items;
  const clasShowTypes = showLine ? styles.showTypes : "";

  useEffect(() => {}, [appliedFilters]);

  return (
    <Swiper
      spaceBetween={12}
      slidesPerView="auto"
      className={`${container} ${show} ${clasShowTypes}`}
    >
      {itemsToRender.map((item, i) => {
        const value = item.props.children;
        const isActive = primary ? checkIsActive(value) : false;
        const order = primary ? 0 : item.props?.promotion?.order;

        return (
          <SwiperSlide
            key={key(i)}
            className={`${slide} ${isActive} ${isActive ? styles.active : ""}`}
            onClick={() => {
              primary && handlerFilters(value);
              // console.log(value)
            }}
            style={primary ? {} : { order }}
          >
            {item}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default SwiperComponent;
