import React from "react";
import styles from "./footer.module.scss";
import Logo from "../../../../images/promo_verano.svg";
import logoItesa from "../../../../images/logoitesa.svg";

import politicas from "./Política de Privacidad promo QR (Bees).pdf";
import terminos from "./Términos y Condiciones Consumidores Promo Verano.pdf";
import comercio from "./Términos y Condiciones Comercios Promo Verano.pdf";

const Footer = ({ secundary, brands, myBrand, terms }) => {
  const typeClass = secundary ? styles.secundary : "";

  return (
    <footer className={`${styles.footer} ${typeClass}`} style={{background:brands.color}} >
      <img src={brands.imgBrand} className={styles.logo} />
      <a href={politicas} target="_blank">
        Politica de privacidad
      </a>
      <a href={terms?.consumers ?? comercio} target="_blank">
        Bases y condiciones consumidores
      </a>
      <a href={terms?.shops} target="_blank">
        Bases y condiciones comercios
      </a>
      <a
        href={"https://itesa.ar/"}
        target="_blank"
        className={styles.itesaContainer}
      >
        <img className={styles.logoItesa} src={logoItesa} />
      </a>
    </footer>
  );
};

export default Footer;
