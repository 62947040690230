import React from "react";
import styles from "./navbar.module.scss";

const Navbar = ({ brands, myBrand }) => {

  // console.log("brands.imgBanner",brands)

  return (
    <header className={styles.navbar}>
      {myBrand && <img src={brands?.imgBanner} alt="banner img" />}
    </header>
  );
};

export default Navbar;
