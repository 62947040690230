import React, { useRef } from "react";
import Swiper from "../Swiper";
import useShow from "../../hooks/useShow";
import styles from "./section.module.scss";
import { array, string, bool } from "prop-types";

const Section = ({
  promotions,
  name,
  primary,
  order,
  allBrands,
  myBrand,
  brands,
}) => {
  const sectionRef = useRef(null);
  const show = useShow();

  let filtered = promotions;

  // console.log("filteeered", filtered);

  // if (myBrand) {
  //   filtered = filtered.filter(
  //     (item) =>
  //       item.brand
  //         .normalize("NFD")
  //         .replace(/[\u0300-\u036f]/g, "")
  //         .trim()
  //         .toLowerCase() === myBrand
  //   );
  // } else if (name) {
  if(name){
    filtered = filtered.filter(
        (item) => item.category === name && item.active === true
    );
  }
  // }

  if (!filtered.length) return null;

  const Card = ({ promotion, brands }) => {
    const cardColor = myBrand ? brands.color : "#E9473D";
    const btnCard = myBrand ? "white" : "rgb(240, 229, 38)";
    const cardTitle = cardColor;

    const capitalize = (string) => {
        if (typeof string !== "string") return "";
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
      <>
        <div
          className={primary ? styles.cardMargin : styles.card}
          style={{ order: promotion.order, backgroundColor: cardColor }}
        >
          <img src={promotion.img} alt="promotion img" />
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <h6 style={{ backgroundColor: cardTitle }}>{promotion.body}</h6>
            <a
              onClick={() => {
                window?.dataLayer?.push({
                  event: "GAEvent",
                  event_category: "Content",
                  event_action: "Button",
                  event_label: "La quiero",
                  interaction: "True",
                  campaign_name: promotion.body,
                  campaign_description: promotion.body,
                  Product: `${capitalize(promotion.type[0])} ${capitalize(promotion.brand)}`
                });
              }}
              href={promotion.url}
              target="_blank"
              alt="promotion url"
              className={styles.buttonContainer}
              style={{
                backgroundColor: btnCard,
                color: "black",
                textDecoration: "none",
              }}
            >
              ¡LA QUIERO!
            </a>
          </div>
        </div>
      </>
    );
  };
  const filteredPromotionsToRender = filtered.map((promotion) => (
    <Card
      key={promotion.body}
      promotion={promotion}
      brand={promotion.brand}
      brands={brands}
    />
  ));

  const renderPromotions = () => {
    return primary ? (
      filtered.map((promotion, i) => (
        <Card
          key={`${i} ${promotion.id}`}
          promotion={promotion}
          brands={brands}
        />
      ))
    ) : (
      <Swiper items={filteredPromotionsToRender} />
    );
  };

  return (
    <section
      key={`section ${name}`}
      id={`section ${name}`}
      className={`${styles.section} ${show}`}
      ref={sectionRef}
      style={{ order, brands }}
    >
      {renderPromotions()}
    </section>
  );
};

Section.defaultProps = {
  promotions: [],
  name: "",
  primary: false,
};

Section.propTypes = {
  promotions: array,
  name: string,
  primary: bool,
};

export default Section;
