import React, { useRef, useEffect } from "react";
import styles from "./modalPocId.module.scss";

const ModalPocId = ({ pocId, handlePocId, validPocId, error, validating }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <div className={styles.background}>
      <div className={styles.mainContainer}>
        <div className={styles.title}>Ingresá el código</div>
        <input
          ref={inputRef}
          type="text"
          // pattern="[0-9]*"
          // inputmode="numeric"
          className={styles.input}
          value={pocId}
          onChange={handlePocId}
        />
        {error && <h1 className={styles.error}>{error}</h1>}
        <a
          target="_blank"
          rel="noreferrer"
          className={styles.mainButton}
          onClick={() => {
            if(!validating){
              validPocId();
              window?.dataLayer?.push({
                event: "GAEvent",
                event_category: "Content",
                event_action: "Validar",
                event_label: pocId,
                interaction: "True",
                component_name: "boton_validar",
                element_text: "Validar",
              });
            }
          }}
        >
          {validating ? "VALIDANDO..." : "VALIDAR!"}
        </a>
      </div>
    </div>
  );
};

export default ModalPocId;
