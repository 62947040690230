import React, { useRef, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import styles from "./agegateNew.module.scss";
import logo from "../../images/fullLogo.svg";
import {
  calculateAge,
  calculateMonth,
  toNumbers,
  calculateDate,
} from "../../utils/utils";
import { navigate } from "gatsby";

import Footer from "../footer/footer";

const bellowAgeLink =
  "https://www.cerveceriaymalteriaquilmes.com/consumo-responsable/";
const Agegate = ({ location, brand, setAge, isMobile }) => {
  const [date, setDate] = useState({
    dd: ["", ""],
    mm: ["", ""],
    aaaa: ["", "", "", ""],
  });
  const [calendar, setCalendar] = useState("AÑO");
  const [remember, setRemember] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const inputYear1 = useRef(null);
  const inputYear2 = useRef(null);
  const inputYear3 = useRef(null);
  const inputYear4 = useRef(null);
  const inputMonth1 = useRef(null);
  const inputMonth2 = useRef(null);
  const inputDay1 = useRef(null);
  const inputDay2 = useRef(null);

  let newPath = location.state
    ? location.state.newPath
      ? location.state.newPath
      : "/"
    : "/";

  const handleAge = (e) => {
    let { name, value } = e.target;
    let newDate = { ...date };
    //YEAR
    if (name === "A1") {
      if (value.length === 1 && e.target.validity.valid) {
        inputYear2.current.focus();
        newDate.aaaa[0] = value;
        setDate(newDate);
      } else {
        newDate.aaaa[0] = "";
        setDisabled(true);
        setDate(newDate);
      }
    }
    if (name === "A2") {
      if (value.length === 1 && e.target.validity.valid) {
        inputYear3.current.focus();
        newDate.aaaa[1] = value;
        setDate(newDate);
      } else {
        newDate.aaaa[1] = "";
        setDate(newDate);
        setDisabled(true);
        inputYear1.current.focus();
      }
    }
    if (name === "A3") {
      if (value.length === 1 && e.target.validity.valid) {
        newDate.aaaa[2] = value;
        setDate(newDate);
        inputYear4.current.focus();
      } else {
        newDate.aaaa[2] = "";
        setDate(newDate);
        setDisabled(true);
        inputYear2.current.focus();
      }
    }
    if (name === "A4") {
      if (value.length === 1 && e.target.validity.valid) {
        newDate.aaaa[3] = value;
        setDate(newDate);
        checkAge(newDate, "year");
      } else {
        newDate.aaaa[3] = "";
        setDate(newDate);
        setDisabled(true);
        inputYear3.current.focus();
      }
    }
    //MONTH
    if (name === "M1") {
      if (value.length === 1 && e.target.validity.valid) {
        inputMonth2.current.focus();
        newDate.mm[0] = value;
        setDate(newDate);
      } else {
        newDate.mm[0] = "";
        setDate(newDate);
        setDisabled(true);
      }
    }
    if (name === "M2") {
      if (value.length === 1 && e.target.validity.valid) {
        newDate.mm[1] = value;
        setDate(newDate);
        checkAge(newDate, "month");
      } else {
        newDate.mm[1] = "";
        setDate(newDate);
        setDisabled(true);
        inputMonth1.current.focus();
      }
    }
    //DAY
    if (name === "D1") {
      if (value.length === 1 && e.target.validity.valid) {
        inputDay2.current.focus();
        newDate.dd[0] = value;
        setDate(newDate);
      } else {
        newDate.dd[0] = "";
        setDate(newDate);
        setDisabled(true);
      }
    }
    if (name === "D2") {
      if (value.length === 1 && e.target.validity.valid) {
        newDate.dd[1] = value;
        setDate(newDate);
        checkAge(newDate, "day");
      } else {
        newDate.dd[1] = "";
        setDate(newDate);
        setDisabled(true);
        inputDay1.current.focus();
      }
    }
  };

  const checkAge = (date, who) => {
    let birthday = new Date();
    if (who === "year") {
      let year = birthday.getUTCFullYear();
      if (year - toNumbers(date.aaaa) < 18) {
        window.location.href = bellowAgeLink;
      }
      if (year - toNumbers(date.aaaa) >= 19) {
        if (date.aaaa.length === 4) {
          sessionStorage.setItem("age", "true");
          setDisabled(false);
        } else {
          sessionStorage.clear();
          setDisabled(true);
        }
      } else {
        setCalendar("MES");
        setTimeout(() => {
          inputMonth1.current.focus();
        }, 1);
      }
    }
    if (who === "month") {
      birthday.setDate(1);
      birthday.setMonth(toNumbers(date.mm) - 1);
      birthday.setFullYear(toNumbers(date.aaaa));
      let today = new Date();
      if (toNumbers(date.mm) - 1 > today.getMonth()) {
        setDate({
          dd: ["", ""],
          mm: ["", ""],
          aaaa: ["", "", "", ""],
        });
        return (window.location.href = bellowAgeLink);
      }
      if (calculateMonth(birthday)) {
        sessionStorage.setItem("age", "true");
        setDisabled(false);
      } else {
        setCalendar("DÍA");
        setTimeout(() => {
          inputDay1.current.focus();
        }, 1);
      }
    }
    if (who === "day") {
      birthday.setDate(toNumbers(date.dd));
      birthday.setMonth(toNumbers(date.mm) - 1);
      birthday.setFullYear(toNumbers(date.aaaa));
      if (calculateDate(birthday)) {
        sessionStorage.setItem("age", "true");
        setDisabled(false);
      } else {
        setCalendar("AÑO");
        setDisabled(false);
        window.location.href = bellowAgeLink;
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    remember && localStorage.setItem("age", "true");
    setAge(true);
    // return navigate(newPath, { replace: true });
  };


  return (
    <div
      className={styles.superContainer}
      style={{
        backgroundColor: brand?.ageGateColor,
        backgroundImage:
          !brand?.ageGateUseColor &&
          `url(${encodeURI(
            isMobile
              ? brand?.ageGateMobileBackgroundImg
              : brand?.ageGateBackgroundImg,
          )})`,
        backgroundSize: "cover",
      }}
    >
      {!brand?.ageGateUseColor && <div className={styles.filter}></div>}
      <div className={styles.container}>
        <img src={encodeURI(brand?.ageGateLogoImg)}></img>
        <h1 className={styles.fadeIn}>{brand?.ageGateText}</h1>
        <div className={styles.fade}>
          <div className={styles.actions}>
            <form onSubmit={onSubmit}>
              {/*  {calendar === "AÑO" && (*/}
              {/*    <div className={styles.inputs}>*/}
              {/*      <input*/}
              {/*        required*/}
              {/*        maxLength={1}*/}
              {/*        max="2"*/}
              {/*        name={"A1"}*/}
              {/*        placeholder="A"*/}
              {/*        onChange={handleAge}*/}
              {/*        value={date.aaaa[0]}*/}
              {/*        ref={inputYear1}*/}
              {/*        type="text"*/}
              {/*        pattern="[0-9]*"*/}
              {/*      />*/}
              {/*      <input*/}
              {/*        required*/}
              {/*        maxLength="1"*/}
              {/*        max="9"*/}
              {/*        name={"A2"}*/}
              {/*        onChange={handleAge}*/}
              {/*        placeholder="A"*/}
              {/*        value={date.aaaa[1]}*/}
              {/*        ref={inputYear2}*/}
              {/*        type="text"*/}
              {/*        pattern="[0-9]*"*/}
              {/*      />*/}
              {/*      <input*/}
              {/*        required*/}
              {/*        maxLength={1}*/}
              {/*        max={9}*/}
              {/*        name={"A3"}*/}
              {/*        onChange={handleAge}*/}
              {/*        placeholder="A"*/}
              {/*        value={date.aaaa[2]}*/}
              {/*        ref={inputYear3}*/}
              {/*        type="text"*/}
              {/*        pattern="[0-9]*"*/}
              {/*      />*/}
              {/*      <input*/}
              {/*        required*/}
              {/*        maxLength={1}*/}
              {/*        max={9}*/}
              {/*        name={"A4"}*/}
              {/*        onChange={handleAge}*/}
              {/*        placeholder="A"*/}
              {/*        value={date.aaaa[3]}*/}
              {/*        ref={inputYear4}*/}
              {/*        type="text"*/}
              {/*        pattern="[0-9]*"*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  )}*/}
              {/*  {calendar === "MES" && (*/}
              {/*    <div className={styles.inputs}>*/}
              {/*      <input*/}
              {/*        required*/}
              {/*        maxLength={1}*/}
              {/*        max={1}*/}
              {/*        name={"M1"}*/}
              {/*        onChange={handleAge}*/}
              {/*        placeholder="M"*/}
              {/*        value={date.mm[0]}*/}
              {/*        ref={inputMonth1}*/}
              {/*        type="text"*/}
              {/*        pattern="[0-9]*"*/}
              {/*      />*/}
              {/*      <input*/}
              {/*        required*/}
              {/*        maxLength={1}*/}
              {/*        max={2}*/}
              {/*        name={"M2"}*/}
              {/*        onChange={handleAge}*/}
              {/*        placeholder="M"*/}
              {/*        value={date.mm[1]}*/}
              {/*        ref={inputMonth2}*/}
              {/*        type="text"*/}
              {/*        pattern="[0-9]*"*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  )}*/}
              {/*  {calendar === "DÍA" && (*/}
              {/*    <div className={styles.inputs}>*/}
              {/*      <input*/}
              {/*        max={3}*/}
              {/*        required*/}
              {/*        name={"D1"}*/}
              {/*        type="text"*/}
              {/*        pattern="[0-9]*"*/}
              {/*        maxLength={1}*/}
              {/*        ref={inputDay1}*/}
              {/*        placeholder="D"*/}
              {/*        value={date.dd[0]}*/}
              {/*        onChange={handleAge}*/}
              {/*      />*/}
              {/*      <input*/}
              {/*        max={9}*/}
              {/*        required*/}
              {/*        name={"D2"}*/}
              {/*        type="text"*/}
              {/*        pattern="[0-9]*"*/}
              {/*        maxLength={1}*/}
              {/*        placeholder="D"*/}
              {/*        ref={inputDay2}*/}
              {/*        value={date.dd[1]}*/}
              {/*        onChange={handleAge}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  )}*/}
              <div className={styles.buttonsContainer}>
                <div className={styles.button}>
                  <button type={"submit"}>SI</button>
                </div>
                <div className={styles.button}>
                  <button
                    onClick={() => (window.location.href = bellowAgeLink)}
                  >
                    NO
                  </button>
                </div>
              </div>
            </form>
            <div className={styles.remember}>
              {<input type="checkbox" id="remember" />}
              {
                <label for="remember" onClick={() => setRemember(true)}>
                  {" "}
                  RECORDAR MIS DATOS{" "}
                </label>
              }
            </div>
          </div>
        </div>
        <div className={styles.description}>
          <p>PARA CONTINUAR DEBES SER MAYOR DE EDAD</p>
        </div>
      </div>
      {/*<footer className={styles.footer}>*/}
      {/*  <Footer />*/}
      {/*</footer>*/}
    </div>
  );
};

export default Agegate;
