import React, { useEffect, useState } from "react";
import Section from "../Section";
import Navbar from "../../components/Layout/components/Navbar";
import Footer from "../../components/Layout/components/Footer";
import styles from "./home.module.scss";
import ModalPocId from "../ModalPocID/ModalPocId";
import { getPocsByPocId } from "../../firebase";
import Agegate from "../Agegate/agegateNew";

const Home = ({
  allPromotions,
  allCategories,
  allBrands,
  allTerms,
  location,
}) => {
  const [pocId, setPocId] = useState("");
  const [samePoc, setSamePoc] = useState(false);
  const [error, setError] = useState("");
  const [promotions, setPromotions] = useState([]);
  const [brands, setBrands] = useState([]);
  const [terms, setTerms] = useState([]);
  const [myBrand, setMyBrand] = useState("");
  const [pocData, setPocData] = useState({});
  const [validating, setValidating] = useState(false);
  const [age, setAge] = useState(false);

  const isBrowser = () => typeof window !== "undefined"
  const isMobile = isBrowser() ? window.innerWidth < 768 : false;

  useEffect(() => {
    setAge(localStorage.getItem("age") === "true");
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const mybrand = window.location.pathname
        .split("/")
        .join(" ")
        .replace(/-/g, " ")
        .trim();
      setMyBrand(mybrand);
    }
  }, []);

  // console.log("myBrand", myBrand);
  // console.log("prmotions", promotions);

  useEffect(() => {
    getPromotions();
  }, [pocData]);

  const initialState = {
    categories: [],
    brands: [],
    types: [],
    showBy: {},
  };
  const [filters, setFilters] = useState(initialState);
  const [appliedFilters, setAppliedFilters] = useState([]);

  useEffect(() => {
    const bdata = allBrands;
    const selectedBrand = bdata.find(
      (item) =>
        item.name
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .trim()
          .toLowerCase() === myBrand.toLowerCase(),
    );
    if (selectedBrand) {
      setBrands(selectedBrand);
    }

    const tdata = allTerms;
    const selectedTerm = tdata.find(
      (item) => item.brand.toLowerCase() === myBrand.toLowerCase(),
    );

    if (selectedTerm) {
      setTerms(selectedTerm);
    }
  });

  useEffect(() => {
    const id = window.location.hash.replace("#", "") || "";
    const element = document.getElementById(`section ${id}`);
    if (id && element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  });

  function checkInterval(hour, intervals) {
    for (let i = 0; i < intervals.length; i++) {
      let interval = intervals[i].split("-").map(Number);
      let start = interval[0];
      let end = interval[1];

      if (start > end) {
        if ((hour >= start && hour <= 23) || (hour >= 0 && hour < end)) {
          return true;
        }
      } else if (hour >= start && hour < end) {
        return true;
      }
    }
    return false;
  }

  const getPromotions = async () => {
    try {
      const data = allPromotions;
      data.map((item) => {
        item.category = item.category.toLowerCase().trim();
        item.brand = item.brand.toLowerCase().trim();
      });

      // const dataFiltered =
      //   window.location.pathname.length > 2
      //     ? data.filter((doc) => {
      //         const brand = doc.brand
      //           .normalize("NFD")
      //           .replace(/[\u0300-\u036f]/g, "")
      //           .toLowerCase();
      //         // console.log("brand", brand);
      //
      //         if (`${brand}` === myBrand) {
      //           return true;
      //         }
      //       })
      //     : data;
      const dataFiltered = data;

      // console.log("dataFiltered", dataFiltered);

      const filters = dataFiltered.reduce((acc, item) => {
        if (!acc.categories?.includes(item.category))
          acc.categories.push(item.category);
        if (!acc.brands?.includes(item.brand)) {
          acc.brands.push(item.brand);
          acc.showBy[item.category]
            ? acc.showBy[item.category].push(item.brand)
            : (acc.showBy[item.category] = [item.brand]);
        }
        if (!acc.types?.includes(item.type)) acc.types.push(item.type);

        return acc;
      }, initialState);

      // console.log("promotions", promotions)

      // setPocPromotions(filteredArray);

      if (pocData) {
        // Array de IDs
        const pocPromosIds = pocData.promotion.map((item) => item.value);
        let pocPromosTimes = {};
        pocData.promotion.forEach((item) => {
          if (item.days && item.hours) {
            pocPromosTimes[item.value] = { hours: item.hours, days: item.days };
          }
        });
        // console.log("pocPromosIds", pocPromosIds);
        const date = new Date(
          new Date().toLocaleString("en-US", {
            timeZone: "America/Argentina/Buenos_Aires",
          }),
        );

        const day = date.getDay();
        const hour = date.getHours();

        const filteredArray = dataFiltered.filter((item) => {
          return (
            pocPromosIds.includes(item.id) &&
            item.active &&
            checkInterval(hour, pocPromosTimes[item.id]?.hours ?? item.hours) &&
            (pocPromosTimes[item.id]
              ? pocPromosTimes[item.id].days.includes(day)
              : item.days.includes(day))
          );
        });

        if (filteredArray.length > 0) {
          setFilters(filters);
          setPromotions(filteredArray);
        } else {
          window?.dataLayer?.push({
            event: "GAEvent",
            event_category: "Content",
            event_action: "Error",
            event_label: "Sin Promo",
            interaction: "True",
            component_name: "boton_validar",
            element_text: "Validar",
          });
          setError("No hay promociones disponibles");
          setSamePoc(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const handlerFilters = (filter) => {
  //   const categoryApplied = filters.categories.filter((category) =>
  //     appliedFilters.includes(category)
  //   );
  //   const brandApplied = filters.brands.filter((brand) =>
  //     appliedFilters.includes(brand)
  //   );
  //   const brandToRemove = categoryApplied.length
  //     ? filters.showBy[filter] || []
  //     : [];
  //   const isCategory = filters.categories.includes(filter);

  //   if (!appliedFilters.includes(filter)) {
  //     if (!categoryApplied.length && brandApplied.length && isCategory) {
  //       const newFilters = brandApplied.filter((brand) =>
  //         filters.showBy[filter].includes(brand)
  //       );
  //       return setAppliedFilters([...newFilters, filter]);
  //     }
  //     return setAppliedFilters([...appliedFilters, filter]);
  //   }

  //   let typeToRemove = [];
  //   if (
  //     (filter === "cervezas" || filter === "gaseosas") &&
  //     !(
  //       categoryApplied.includes("cervezas") &&
  //       categoryApplied.includes("gaseosas")
  //     )
  //   )
  //     typeToRemove = filters.types;

  //   const newFilter = appliedFilters.filter((item) => {
  //     if (brandToRemove.includes(item) || typeToRemove.includes(item))
  //       return false;
  //     return item !== filter;
  //   });
  //   setAppliedFilters(newFilter);
  // };

  // const checkIsActive = (item) =>
  //   appliedFilters.some((filter) => filter === item);

  const getPromotionsFiltered = () => {
    const categoryApplied = filters.categories.filter((category) =>
      appliedFilters.includes(category),
    );
    const brandApplied = filters.brands.filter((brand) =>
      appliedFilters.includes(brand),
    );
    const typeApplied = filters.types.filter((type) =>
      appliedFilters.includes(type),
    );

    const promotionsFiltered = promotions.filter((promotion) => {
      if (
        (!categoryApplied.length ||
          categoryApplied.includes(promotion.category)) &&
        (!brandApplied.length || brandApplied.includes(promotion.brand)) &&
        (!typeApplied.length || typeApplied.includes(promotion.type))
      )
        return true;

      return false;
    });
    return promotionsFiltered;
  };
  const promotionsFiltered = getPromotionsFiltered();

  const getOrder = (category) =>
    allCategories.reduce((acc, item) => {
      if (item.name === category) acc = item.order;
      return acc;
    }, 0);

  const handlePocId = (e) => {
    setPocId(e.target.value);
  };

  const validPocId = async () => {
    if (pocId) {
      setValidating(true);
      const pocs = await getPocsByPocId(pocId.toUpperCase());

      const filteredData = pocs.filter((doc) => {
        const brand = doc.brand
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
        return brand.toLowerCase().trim() === myBrand.toLowerCase().trim();
      });
      if (
        filteredData.length > 0 &&
        filteredData[0].brand
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .trim()
          .toLowerCase() === myBrand
      ) {
        setPocData(filteredData[0]);
        setSamePoc(true);
      } else {
        window?.dataLayer?.push({
          event: "GAEvent",
          event_category: "Content",
          event_action: "Error",
          event_label: "Codigo invalido",
          interaction: "True",
          component_name: "boton_validar",
          element_text: "Validar",
        });
        setError("Código inválido");
      }
    } else {
      window?.dataLayer?.push({
        event: "GAEvent",
        event_category: "Content",
        event_action: "Error",
        event_label: "Codigo invalido",
        interaction: "True",
        component_name: "boton_validar",
        element_text: "Validar",
      });
      setError("Debe ingresar código");
    }
    setValidating(false);
  };

  return !age ? (
    <Agegate location={location} brand={brands} setAge={setAge} isMobile={isMobile}/>
  ) : (
    <>
      <Navbar
        allBrands={allBrands}
        brands={brands}
        myBrand={myBrand}
        terms={terms}
      />
      <div
        className={styles.mainContainer}
        style={{ backgroundColor: brands.backgroundColor }}
      >
        {samePoc ? (
          <>
            <div className={styles.textContent}>
              {myBrand ? (
                <h1>{brands.bannerTitle}</h1>
              ) : (
                <p>Seleccioná tu promoción favorita</p>
              )}
            </div>

            {/* ----- Filters ----- */}

            {/* <div className={styles.filtersContainer}>
            <Swiper
              items={filters}
              primary
              level={"categories"}
              page={"home_page"}
              handlerFilters={handlerFilters}
              checkIsActive={checkIsActive}
              appliedFilters={appliedFilters}
              setAppliedFilters={setAppliedFilters}
              brands={brands}
              myBrand={myBrand}
              allBrands={allBrands}
            />
            {showBrands && (
              <Swiper
                items={filters}
                primary
                level={"brands"}
                page={"home_page"}
                handlerFilters={handlerFilters}
                checkIsActive={checkIsActive}
                appliedFilters={appliedFilters}
                showLine={showBrands}
                brands={brands}
                myBrand={myBrand}
                allBrands={allBrands}
              />
            )}
            {showTypes && (
              <Swiper
                items={filters}
                primary
                level={"types"}
                page={"home_page"}
                handlerFilters={handlerFilters}
                checkIsActive={checkIsActive}
                appliedFilters={appliedFilters}
                showLine={showTypes}
                brands={brands}
                myBrand={myBrand}
                allBrands={allBrands}
              />
            )}
          </div> */}

            {appliedFilters.length ? (
              <Section
                name={""}
                promotions={promotionsFiltered}
                primary
                isFiltered
                page={"home_page"}
                allBrands={allBrands}
                brands={brands}
                myBrand={myBrand}
              />
            ) : filters.categories.length ? (
              <div className={styles.sectionsContainer}>
                {filters.categories.map((item) => (
                  <Section
                    key={`section ${item}`}
                    name={item}
                    promotions={promotions}
                    primary
                    page={"home_page"}
                    order={getOrder(item)}
                    allBrands={allBrands}
                    brands={brands}
                    myBrand={myBrand}
                  />
                ))}
              </div>
            ) : (
              <div></div>
            )}
          </>
        ) : (
          <ModalPocId
            pocId={pocId}
            handlePocId={handlePocId}
            validPocId={validPocId}
            error={error}
            validating={validating}
          />
        )}
      </div>

      <Footer brands={brands} myBrand={myBrand} terms={terms} />
    </>
  );
};

export default Home;
