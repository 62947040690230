import { useEffect, useState } from 'react';

const ShowComponent = () => {
    const [show, setShow] = useState('');

    useEffect(() => {
       setTimeout(() => setShow('show'), 500);
    }, [])

    return show;

};

export default ShowComponent;