import { initializeApp } from "firebase/app";
import {
    getFirestore,
    collection,
    getDocs,
    query,
    where,
} from "firebase/firestore";
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAhUzbwNRgflTutVVKDs7TU2h2mbM0nYSc",
    authDomain: "trade-on-premise.firebaseapp.com",
    projectId: "trade-on-premise",
    storageBucket: "trade-on-premise.appspot.com",
    messagingSenderId: "489678419598",
    appId: "1:489678419598:web:debbe98a094e046fae3bdf",
};
// Initialize Firebase
initializeApp(firebaseConfig);
export const db = getFirestore();


export const getPocsByPocId = async (pocId) => {
    const q = query(collection(db, "contacts"), where("pocId", "==", pocId));
    const querySnapshot = await getDocs(q);
    let data = [];
    querySnapshot.forEach((doc) => {
        let docData = doc.data();
        docData.id = doc.id;
        data.push(docData);
    });
    return data;
};