import React, { useEffect, useState } from "react";
import styles from "./layout.module.scss";

const Layout = ({ children, allBrands }) => {
  const [myBrand, setMyBrand] = useState();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const mybrand = window.location.pathname
        .split("/")
        .join(" ")
        .replace(/-/g, " ")
        .trim();

      const selectedBrand = allBrands?.find(
        (item) =>
          item.name
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .trim()
            .toLowerCase() === mybrand.toLowerCase()
      );
      setMyBrand(selectedBrand);
    }
  });

  return (
    <main
      className={styles.layout}
      style={{ backgroundColor: myBrand?.backgroundColor }}
    >
      {children}
    </main>
  );
};

export default Layout;
